<template>
  <div class="nav-container" :style="screenWidths > 820 ? '' : 'height:40px;'">
    <el-row style="height: 100%">
      <el-col
        style="height: 100%"
        :xs="{ span: 20, offset: 2 }"
        :sm="{ span: 20, offset: 2 }"
        :md="{ span: 20, offset: 2 }"
        :lg="{ span: 20, offset: 2 }"
        :xl="{ span: 16, offset: 4 }"
      >
        <div class="nav-content">
          <el-image
            :class="screenWidths > 820 ? 'logo' : 'logoYD'"
            :src="logo"
            @click="getInHome"
          ></el-image>
          <div class="nav-content-main">
            <template v-if="screenWidths > 820">
              <ul class="nav-ul">
                <li class="nav-li" v-for="item in list" :key="item.id">
                  <div
                    style="font-size: 16px"
                    :class="
                      clickListId == item.id - 1
                        ? 'nav-li-text nav-li-textClick'
                        : 'nav-li-text'
                    "
                    @click="getOpenItemPC(item)"
                  >
                    {{ item.name }}
                  </div>
                  <ul class="nav-li-ul">
                    <li
                      class="nav-li-ul-li"
                      v-for="itemChilden in item.childen"
                      :key="itemChilden.id"
                    >
                      <div
                        style="font-size: 16px"
                        :class="
                          itemChildenOpen == itemChilden.id
                            ? 'nav-li-ul-li-text nav-li-ul-li-textClick'
                            : 'nav-li-ul-li-text'
                        "
                        @click="getOpenItemChilden(itemChilden.id, item)"
                      >
                        {{ itemChilden.name }}
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </template>
            <template v-else>
              <div class="nav-open" v-show="!open" @click="handleOpen">
                <img src="@/assets/image/open.png" alt="" srcset="" />
              </div>
              <div class="nav-fold" v-show="open" @click="handleClose">
                <img src="@/assets/image/fold.png" alt="" srcset="" />
              </div>
            </template>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-drawer
      :visible.sync="open"
      direction="rtl"
      :with-header="false"
      size="50%"
      class="drawer_fwd"
    >
      <div class="nav-main">
        <div v-for="item in list" :key="item.id">
          <div class="nav-main-item2" @click="getOpenItem(item.id)">
            <div class="nav-main-item2-text">{{ item.name }}</div>
            <img
            v-if="item.id != 1"
              class="nav-main-itemChilden-img"
              src="@/assets/image/drawer_opne.png"
            />
          </div>
          <template v-if="itemDrawerOpen == item.id && item.id != 1">
            <div v-for="itemChilden in item.childen" :key="itemChilden.id">
              <div
                :class="
                  itemChildenOpen == itemChilden.id
                    ? 'nav-main-itemChilden navChidenClick'
                    : 'nav-main-itemChilden'
                "
                @click="getOpenItemChilden(itemChilden.id, item)"
              >
                {{ itemChilden.name }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import logo from "@/assets/image/logo.jpg";
export default {
  data() {
    return {
      itemDrawerOpen: -1,
      itemChildenOpen: -1,
      logo: logo,
      clickListId: 0,
      screenWidths: 0,
      open: false,
      list: [
        {
          id: 1,
          routerName: "home",
          name: "首页",
          childen: [
            {
              id: 11,
              name: "关于融智云",
            },
            {
              id: 12,
              name: "软件外包服务",
            },
            {
              id: 13,
              name: "人员驻点服务",
            },
            {
              id: 14,
              name: "解决方案",
            },
          ],
        },

        {
          id: 2,
          routerName: "aboutUs",
          name: "关于融智云",
          childen: [
            {
              id: 21,
              name: "公司介绍",
            },
            {
              id: 22,
              name: "公司资质",
            },
            {
              id: 23,
              name: "我们的客户",
            },
          ],
        },
        {
          id: 3,
          routerName: "softwareOutsourc",
          name: "软件外包",
          childen: [
            {
              id: 32,
              name: "通用框架模式",
            },
            {
              id: 31,
              name: "特定平台模式",
            },

            {
              id: 33,
              name: "项目管理流程",
            },
          ],
        },
        {
          id: 4,
          routerName: "personnelAssignment",
          name: "人员驻点",
          childen: [
            {
              id: 41,
              name: "长期合作模式",
            },
            {
              id: 42,
              name: "临时驻场模式",
            },
            {
              id: 43,
              name: "主要合作单位",
            },
          ],
        },
        {
          id: 5,
          routerName: "solution",
          name: "解决方案",
          childen: [
            {
              id: 51,
              name: "数字化校园",
            },
            {
              id: 52,
              name: "智慧乡镇",
            },
            {
              id: 53,
              name: "智慧城市网格化",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.clickListId = sessionStorage.getItem("clickListId")
      ? sessionStorage.getItem("clickListId")
      : 0;
    this.getToNav(this.list[this.clickListId]);
    this.$nextTick(function () {
      this.resize();
    });
    setTimeout(() => {
      this.itemChildenOpen = sessionStorage.getItem("itemChildenOpen")
        ? sessionStorage.getItem("itemChildenOpen")
        : this.itemChildenOpen;
      this.$emit("getScrollView", this.itemChildenOpen);
    }, 500);
  },
  mounted() {
    let _this = this;
    _this.$nextTick(function () {
      window.addEventListener("resize", this.resize);
    });
  },
  methods: {
    getInHome() {
      this.clickListId = 0;
      sessionStorage.setItem("clickListId", this.clickListId);

      this.itemChildenOpen = 11;
      sessionStorage.setItem("itemChildenOpen", this.itemChildenOpen);
      sessionStorage.setItem("itemDrawerOpen",  -1);
      this.$router.push({
        name: "home",
      });
    },
    getOpenItem(id) {
      if(id == 1){
        this.getInHome();

        return
      }
      if (this.itemDrawerOpen == id) {
        this.itemDrawerOpen = -1;
      } else {
        this.itemDrawerOpen = id;
        
      }
      sessionStorage.setItem("itemDrawerOpen",  this.itemDrawerOpen);
    },
    getOpenItemChilden(id, item) {
      this.itemChildenOpen = id;
      sessionStorage.setItem("itemChildenOpen", this.itemChildenOpen);
      this.open = false;
      if (this.clickListId == item.id - 1) {
        this.$emit("getScrollView", id);
      } else {
        this.getToNav(item);
      }
    },

    getOpenItemPC(item) {
      this.itemChildenOpen = -1;
      sessionStorage.setItem("itemChildenOpen", this.itemChildenOpen);
      this.getToNav(item);
    },

    getToNav(item) {
      this.clickListId = item.id - 1;
      sessionStorage.setItem("clickListId", this.clickListId);
      this.$router.push({
        name: item.routerName,
      });
    },
    resize() {
      let _this = this;
      return (() => {
        window.screenWidths = document.body.clientWidth; //获取页面装口宽度
        _this.screenWidths = window.screenWidths; //赋值screenWidths
        this.$emit("getScreenWidths", _this.screenWidths);
      })();
    },
    handleOpen() {
      this.open = true;
     this.itemDrawerOpen = sessionStorage.getItem("itemDrawerOpen")
      ? sessionStorage.getItem("itemDrawerOpen")
      : -1;
      console.log('itemDrawerOpen',this.itemDrawerOpen )
    },
    handleClose() {
      this.open = false;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
};
</script>

<style  scoped>
.nav-container {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 9999;
  border-bottom: 1px solid rgba(204, 204, 204, 0.7);
}

.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.logo {
  width: 166px;
  height: 80px;
  cursor: pointer;
}
.logoYD {
  height: 40px;
  width: 83px;
  cursor: pointer;
}

.nav-content-main {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 16px;
}
ol,
ul,
li {
  list-style: none;
}
.nav-ul {
  display: flex;
}
.nav-li {
  position: relative;
}

.nav-li-text {
  width: 80px;
  text-align: center;
  padding: 30px 16px;
  cursor: pointer;
}
.nav-li-text:hover {
  color: #076ce0;
}

.nav-li-textClick {
  border-bottom: 3px solid #076ce0;
  color: #076ce0;
}

.nav-content-main .nav-li:hover ul {
  display: block;
  position: absolute;
}

.nav-li-ul {
  display: none;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);
}

.nav-li-ul-li-text {
  text-align: center;
  padding: 16px 0;
}
.nav-li-ul-li-text:hover {
  overflow: hidden;
  color: #076ce0;
  cursor: pointer;
}

.nav-li-ul-li-textClick {
  background-color: #076ce0;
  color: #ffffff;
}
.nav-li-ul-li-textClick:hover {
  color: #ffffff;
}

/* width: 820px; */

.nav-open {
  width: 30px;
  height: 30px;
}
.nav-open img {
  width: 100%;
  height: 100%;
}

.nav-fold {
  width: 30px;
  height: 30px;
}
.nav-fold img {
  width: 100%;
  height: 100%;
}

.drawer_fwd {
  top: 40px;
  height: calc(100% - 40px);
  border-top: 1px solid #999;
}

.nav-main {
  width: 100%;
}

.nav-main-item2 {
  width: calc(100% - 32px);
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
  display: flex;
  align-items: center;
}
.nav-main-item2-text {
  font-size: 16px;
  color: #333;
}
.nav-main-itemChilden-img {
  width: 10px;
  height: 10px;
  margin-left: 4px;
}
.nav-main-item2-img img {
  width: 80%;
  height: 80%;
}
.nav-main-itemChilden {
  width: calc(100% - 32px);
  font-size: 16px;
  color: #333;
  padding-left: 32px;
  height: 30px;
  line-height: 30px;
}
.nav-main-itemChilden:active {
  background-color: #076ce0c7;
  color: #ffffff;
}
.navChidenClick {
  background-color: #076ce0c7;
  color: #ffffff;
}
</style>