<template>
  <div>
    <navbar
      ref="navbar"
      @getScreenWidths="getScreenWidths"
      @getScrollView="getScrollView"
    ></navbar>

    <div
      class="container"
      :style="screenWidths > 820 ? '' : 'padding-top:40px;'"
    >
      <div class="topShow">
        <img
          class="topShowImg"
          src="@/assets/image/personnelAssignment/top.png"
          alt=""
        />
      </div>

      <el-row style="margin: 0; display: flex; flex-wrap: wrap">
        <el-col
          :xs="{ span: 24, offset: 0 }"
          :sm="{ span: 24, offset: 0 }"
          :md="{ span: 24, offset: 0 }"
          :lg="{ span: 20, offset: 2 }"
          :xl="{ span: 16, offset: 4 }"
        >
          <div class="title" ref="personnel1">
            <div class="title-t">人员驻点</div>
            <div class="title-b"></div>
          </div>

          <!-- 合作模式 -->
          <div class="list">
            <div class="listItem">
              <div class="listItem_i bounce-in-left">
                <img src="@/assets/image/personnelAssignment/tab1.png" alt="" />
              </div>
              <div class="listItem_t bounce-in-left">
                <div class="listItem_t_title">长期合作模式</div>
                <div class="listItem_t_b"></div>
                <div class="listItem_t_text">
                  <div>1、磋商</div>
                  <div>
                    2、签订框架协议：人员管理方式
                    、通用保密条款、费用结算方式、人员服务单价、人员进场单、人员离场单、考核规范等
                  </div>
                  <div>3、人员面试：人员定级、三方保密协议、进场培训</div>
                </div>
              </div>
            </div>
            <div class="listItem">
              <div class="listItem_i bounce-in-right">
                <img src="@/assets/image/personnelAssignment/tab2.png" alt="" />
              </div>
              <div class="listItem_t bounce-in-right">
                <div class="listItem_t_title">临时驻场模式</div>
                <div class="listItem_t_b"></div>
                <div class="listItem_t_text">
                  <div>1、磋商</div>
                  <div>2、人员面试：人员能力评定。</div>
                  <div>
                    3、签订框架协议：驻点服务期限、 通用保密条款、
                    人员服务单价、费用结算方式
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 主要合作单位 -->
          <div class="main_cooperation">
            <div class="title" ref="personnel2">
              <div class="title-t">主要合作单位</div>
              <div class="title-b"></div>
            </div>
            <el-row
              :gutter="32"
              style="margin: 0; display: flex; flex-wrap: wrap"
            >
              <el-col
                :xs="{ span: 24, offset: 0 }"
                :sm="{ span: 24, offset: 0 }"
                :md="{ span: 24, offset: 0 }"
                :lg="{ span: 24, offset: 0 }"
                :xl="{ span: 24, offset: 0 }"
              >
                <div
                  :class="
                    effect2 ? 'main_cooperation_i bounce-in-right' : 'disnone'
                  "
                >
                  <img
                    src="@/assets/image/personnelAssignment/tabMain.png"
                    alt=""
                  />
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import navbar from "../../components/navbar.vue";
export default {
  components: { navbar },
  data() {
    return {
      effect2: false,
      screenWidths: 0,
    };
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("scroll", this.onScroll);
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    getScreenWidths(val) {
      this.screenWidths = val;
    },
    // 滚动条
    getScrollView(id) {
      if (id == 41 || id == 42) {
        this.$refs.personnel1.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      } else if (id == 43) {
        this.$refs.personnel2.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    },
    onScroll() {
      let scrolled =
        document.documentElement.scrollTop || document.body.scrollTop;
      //  console.log(scrolled);
      if (scrolled > 300) {
        this.effect2 = true;
      }
      if (this.screenWidths < 820) {
        this.effect2 = true;
      }
    },
  },
};
</script>

<style  scoped>
.bounce-in-left {
  -webkit-animation: bounce-in-left 1.1s both;
  animation: bounce-in-left 1.1s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-1-21 12:47:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-left
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-600px);
    transform: translateX(-600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(-68px);
    transform: translateX(-68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(-28px);
    transform: translateX(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-600px);
    transform: translateX(-600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(-68px);
    transform: translateX(-68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(-28px);
    transform: translateX(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.bounce-in-right {
  -webkit-animation: bounce-in-right 1.1s both;
  animation: bounce-in-right 1.1s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-1-21 12:39:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-right
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(68px);
    transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(32px);
    transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(68px);
    transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(32px);
    transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.disnone {
  display: none;
}

.topShow {
  width: 100%;
  overflow: hidden;
}
.topShowImg {
  width: 100%;
}

.title {
  width: 100%;
  margin-top: 80px;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 80px;
  margin-top: 0px;
  
}
.title-t {
  color: #333;
  font-size: 26px;
  margin-bottom: 16px;
  position: relative;
  font-weight: bold;
  background: #fff;
  overflow: hidden;
}
.title-t::before,
.title-t::after {
  content: "";
  position: absolute;
  top: 100px;
  left: 50%;
  width: 180px;
  height: 180px;
  border-radius: 25% 65% 43% 47%;
  transform: translate(-50%, -50%);
  background: rgba(3, 169, 244, 0.85);
  animation: rotate 10s infinite linear;
  z-index: 1;
  mix-blend-mode: lighten;
}
.title-t::after {
  border-radius: 43% 47% 44% 48%;
  animation: rotate 10s infinite 0.5s linear;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.title-b {
  width: 60px;
  height: 2px;
  background-color: rgb(44, 134, 237);
}
.list {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.listItem {
  width: 300px;
  padding: 32px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 10px rgba(165, 165, 165, 0.3);
  margin: 0 16px 30px;
}
.listItem:hover {
  box-shadow: 0 0 10px rgba(44, 134, 237, 0.3);
}

.listItem_i {
  width: 290px;
  margin-bottom: 16px;
}
.listItem_i img {
  width: 100%;
}

.listItem_t {
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.listItem_t_title {
  text-align: center;
  font-size: 18px;
  color: rgb(102, 102, 102);
}

.listItem_t_b {
  background-color: rgb(44, 134, 237);
  height: 2px;
  width: 60px;
  margin: 16px 0;
}

.listItem_t_text {
  font-size: 14px;
  color: rgb(150, 150, 150);
  line-height: 30px;
}

.main_cooperation {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 80px;
}

.main_cooperation_i {
  width: 100%;
  box-shadow: 0 0 10px rgba(165, 165, 165, 0.2);
}
.main_cooperation_i:hover {
  box-shadow: 0 0 10px rgba(44, 134, 237, 0.3);
}

.main_cooperation_i img {
  width: 100%;
}
</style>